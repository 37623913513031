<template>
  <div class="component" v-if="$store.state.step == 5" id="dogee">

    <div class="dogee__text">
      Всегда рады <br>
      встрече с вами!
    </div>
    <div class="dogee">
      <img :src="dog" alt="">
    </div>

  </div>
</template>

<script>

import dog from '../assets/img/dog.svg';

export default {

  name: 'step_5',

  data: () => {
    return {
      dog
    }

  },

  async mounted() {
  },

  methods: {

  },

  computed: {


  }

}

</script>

<style scoped>
.dogee__text {
  font-size: 18px;
  text-align: center;
  color: #231F20;
  margin: 20px 0 80px;
}

</style>