<template>
  <div id="fb-widget" class="fb" v-if="$store.state.docID != null" >
    <div class="fb-header" >
      <div class="fb-logo">
        <img :src="logo">
      </div>
    </div>

    <step_1 v-if="$store.state.step === 1"/>
    <step_2 v-if="$store.state.step === 2"/>
    <step_3 v-if="$store.state.step === 3"/>
    <step_4 v-if="$store.state.step === 4"/>
    <step_5 v-if="$store.state.step === 5"/>
  </div>

</template>

<script>

import step_1 from './components/step_1.vue'
import step_2 from './components/step_2.vue'
import step_3 from './components/step_3.vue'
import step_4 from './components/step_4.vue'
import step_5 from './components/step_5.vue'
import logo from './assets/img/logo.svg';

export default {
  name: 'App',
  components: {

    step_1,
    step_2,
    step_3,
    step_4,
    step_5

  },
  data() {
    return {
      logo
    };
  },

  async beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let docId = urlParams.get('docId');
    if (docId === null) {
        docId = document.location.pathname.replace('/','').replace('/','')
      if (docId.includes('step')) {
        docId = null;
      }
    }

    if (docId !== null) {
      this.docID = docId
      this.$nextTick(async function () {
        await this.NPSInfo()
      })
    } 
  },

  methods: {
    async NPSInfo() {
      if (this.docID == null || this.docID === '') return;
      try {
        let data = await this.$store.dispatch('NPSInfo')
        this.flagMessenger = data.flagMessenger
      } catch (e) {
      }
    }
  },
  computed: {
    docID: {
      get() {
        return this.$store.state.docID
      },

      set(number) {
        this.$store.commit('SET_DOCID', number)
        return;
      },
    },

    flagMessenger: {
      get() {
        return this.$store.state.flagMessenger
      },

      set(number) {
        this.$store.commit('SET_FLAG_MESSENGER', number)
        return;
      },
    }
  }


}

</script>