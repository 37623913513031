<template>
  <div class="component" v-if="$store.state.step == 3" id="contacts">

    <div class="contacts__title">
      Выберите удобный
      <br>
      для вас способ связи
    </div>

    <div class="contacts__items">
      <div class="contacts__items__wrapper">
        <div class="contacts__items_item" v-for="soc_item in soc_items" :key="soc_item.id">
          <div class="contacts__items_item__wrapper def-tr" @click="setSoc(soc_item.name, $event)">
            <div class="contacts__items_item_img">
              <img :src="soc_item.img" alt="">
            </div>
            <div class="contacts__items_item_text">
              {{ soc_item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contacts__btn">
      <div class="contacts__btn_wrapper">
        <button @click="nextStep" v-bind:class="{active: soc.length}">{{ btn_text }}</button>
      </div>
    </div>

  </div>
</template>

<script>

import sms from '../assets/img/sms.svg';
import viber from '../assets/img/viber.svg';
import whatsapp from '../assets/img/whatsapp.svg';
import telegram from '../assets/img/tg.svg';
import vk from '../assets/img/vk.svg';

export default {

  name: 'step_3',

  data: () => {

    return {
      soc_items: [
        {
          id: 1,
          name: 'SMS',
          link: '1',
          img: sms
        },
        {
          id: 2,
          name: 'Viber',
          link: '1',
          img: viber
        },
        {
          id: 3,
          name: 'WhatsApp',
          link: '1',
          img: whatsapp
        },
        {
          id: 4,
          name: 'Telegram',
          link: '1',
          img: telegram
        },
        {
          id: 5,
          name: 'ВКонтакте',
          link: '1',
          img: vk
        }
      ],
      soc: [],
      btn_text: 'Пропустить',
    }

  },

  async mounted() {
    this.is_loading = false
  },

  methods: {
    setSoc(name, e) {
      e.target.closest('.contacts__items__wrapper').classList.add('active')

      this.soc = []
      let oldActive = document.getElementsByClassName("contacts__items_item__wrapper active")
      Array.prototype.forEach.call(oldActive, function (el) {
        el.classList.remove('active')
      });

      e.target.closest('.contacts__items_item__wrapper').classList.add('active')

      if (e.target.closest('.contacts__items_item__wrapper').classList.contains('active')) {
        this.soc.push(name)
      } else {
        this.soc = this.soc.filter((item) => {
          return item !== name
        })
      }

      if (!this.soc.length) {
        this.btn_text = 'Пропустить'
      } else {
        this.btn_text = 'Отправить'
      }

      this.social = this.soc
    },

    async nextStep() {
      if (this.social) {
        this.$metrika.reachGoal('messenger')
        await this.$store.dispatch('clientFeedbackMessenger')
      }


      this.$store.commit('SET_STEP', 4)


      history.pushState({}, 'Step 4', '/step_4')

    }
  },

  computed: {
    social: {
      get() {
        return this.$store.state.social
      },

      set(number) {
        this.$store.commit('SET_SOCIAL', number)
      }
    },
  }

}

</script>

<style scoped>
.contacts__items__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contacts__items_item_img {
  cursor: pointer;
  width: 72px;
  height: 72px;
  margin: 0 auto;
}

.contacts__items {
  width: 100%;
}

.contacts__items__wrapper {
  padding: 0 40px;
}

.contacts__items_item {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.contacts__title {
  text-align: center;
  margin: 55px 0 60px;
  font-size: 18px;
  color: #231F20;
}

.contacts__items_item_text {
  font-weight: 600;
  text-align: center;
  line-height: 1.6;
  color: #231F20;
  margin-top: 10px;
}

.contacts__btn {
  width: 100%;
  margin-top: 25px;
}

.contacts__btn_wrapper {
  padding: 0 40px;
}

.contacts__btn_wrapper > button {
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(145, 154, 161, .5);
  font-size: 18px;
  color: #919AA1;
}

.contacts__items_item_img img {
  pointer-events: none;
}

.contacts__btn_wrapper > button.active {
  background: #4A6586;
  color: #FFFFFF;
}

.contacts__items__wrapper.active .contacts__items_item__wrapper {
  opacity: .35;
}

.contacts__items__wrapper.active .contacts__items_item__wrapper.active {
  opacity: 1;
}

.contacts__items__wrapper.active .contacts__items_item__wrapper:hover {
  opacity: 1;
}

</style>