<template>
  <div class="component" v-if="$store.state.step == 2" id="recommendation">

    <div class="fb_starts">
      <div v-for="n in 5" :key="'star-' + n" class="fb_starts_item">
        <img :src="rating >= n ? star : star_empty" :data-rate="n" alt="star">
      </div>
    </div>

    <div class="recommendation_change_rating" @click="back">
      <div class="recommendation_change_rating__wrapper">
        Изменить оценку
      </div>
    </div>

    <div class="recommendation_title">
      Порекомендуйте,<br>что нам стоит улучшить
    </div>

    <div class="recommendation_options">
      <div v-for="recOption in recOptions" :key="recOption.id" class="recommendation_options__item" v-show="!comment || rec.indexOf(recOption.name) !== -1">
        <label class="recommendation_options__item_ch">{{ recOption.name }}
          <input @click="setRec(recOption.name, $event)" ref="rec_items" type="checkbox" :value="recOption.id"/>
          <span class="geekmark"></span>
        </label>
      </div>
    </div>

    <div class="recommendation_comment" v-if="comment">
      <div class="recommendation_comment__wrapper">
        <textarea v-model="comment_textArea" name="comment" cols="30" rows="10"></textarea>
      </div>
    </div>

    <div class="recommendation_btn">
      <div class="recommendation_btn__wrapper">
        <button class="def-tr" @click="nextStep" v-bind:class="{active: rec.length}">{{ btn_text }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import star_empty from '../assets/img/star_empty.svg';
import star from '../assets/img/star.svg';

export default {

  name: 'step_2',

  data: () => {

    return {
      star_empty,
      star,
      recOptions: [
        {
          id: 1,
          name: 'Профессионализм'
        },
        {
          id: 2,
          name: 'Гостеприимство'
        },
        {
          id: 3,
          name: 'Комфорт и удобство в автоцентре'
        },
        {
          id: 4,
          name: 'Скорость работы'
        },
        {
          id: 5,
          name: 'Ассортимент услуг'
        },
        {
          id: 6,
          name: 'Другое'
        },
      ],
      rec: [],
      btn_text: 'Пропустить',
      comment: false,
      comment_textArea: '',
      comment_name: 'Другое'
    }
  },

  async mounted() {
    this.is_loading = false
  },

  methods: {
    setRec(name, e) {
      if (e.target.checked) {
        this.rec.push(name)
      } else {
        this.rec = this.rec.filter((item) => {
          return item !== name
        })
      }

      this.showComment(name, e.target.checked)

      if (!this.rec.length) {
        this.btn_text = 'Пропустить'
      } else {
        this.btn_text = 'Отправить'
      }

      this.recommendation = this.rec
    },

    async back(e) {
      history.pushState({}, 'Step 1', '/step_1')
      this.$store.commit('SET_STEP', 1)
    },

    showComment(name, checked) {
      if (name === this.comment_name) {
        this.comment = !!checked;
      } else {
        return
      }
    },

    async nextStep(e) {

      this.comment_text = this.comment_textArea.replace(/(<([^>]+)>)/ig, '')
      if (this.comment_text) {
        this.$metrika.reachGoal('comment')
      } else {
        if (this.recommendation) {
          this.$metrika.reachGoal('upgrade')
        }
      }

      await this.$store.dispatch('clientFeedbackComment')
      history.pushState({}, 'Step 3', '/step_3')

      this.$store.commit('SET_STEP', 3)
    }
  },

  computed: {
    rating: {
      get() {
        return this.$store.state.rating
      },

      set(number) {
        this.$store.commit('SET_OLD_RATING', number)
        return;
      },
    },

    recommendation: {
      get() {
        return this.$store.state.recommendation
      },

      set(number) {
        this.$store.commit('SET_RECOMMENDATION', number)
        return;
      }
    },

    comment_text: {
      get() {
        return this.$store.state.comment_text
      },

      set(text) {
        this.$store.commit('SET_COMMENT', text)
        return;
      }
    }
  }

}

</script>

<style scoped>
#recommendation .fb_starts {
  margin-top: 25px;
}

.recommendation_change_rating {
  text-align: center;
  line-height: 1;
  margin-top: 15px;
  cursor: pointer;
  font-size: 18px;
  color: #231F20;
}

.recommendation_change_rating__wrapper {
  border-bottom: 1px solid rgba(35, 31, 32, .25);
  display: inline-block;
}

.recommendation_title {
  text-align: center;
  line-height: 1.2;
  margin-top: 45px;
  font-size: 18px;
  color: #231F20;
}

.recommendation_options {
  margin-top: 35px;
  line-height: 1.2;
  font-size: 16px;
  color: #231F20;
}

.recommendation_options__item label {
  margin-left: 50px;
  line-height: 1.4;
}

.recommendation_options__item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}

input[type=checkbox] {
  visibility: hidden;
}

.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid #919AA1;
  border-radius: 3px;
}

.recommendation_options__item input:checked ~ .geekmark {
  background: #4A6586;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}


.recommendation_options__item input:checked ~ .geekmark:after {
  display: block;
}

.recommendation_options__item .geekmark:after {
  left: 7px;
  bottom: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.recommendation_btn {
  width: 100%;
  margin: 40px 0;
}

.recommendation_btn__wrapper > button {
  height: 50px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(145, 154, 161, .5);
  font-size: 18px;
  color: #919AA1;
}

.recommendation_btn__wrapper > button.active {
  background: #4A6586;
  color: #FFFFFF;
}

.recommendation_comment {
  width: 100%;
  margin-top: 20px;
}

.recommendation_comment__wrapper {
  display: flex;
}

.recommendation_comment__wrapper > textarea {
  width: 100%;
  border-radius: 5px;
  height: 100px;
  padding: 10px;
  border: 1px solid rgba(145, 154, 161, .5);
  font-size: 16px;
  color: rgba(35, 31, 32, .5);
}

#recommendation {
  padding: 0 40px;
}


#fb-widget .component {
  align-items: stretch;
}

#recommendation .fb_starts {
  justify-content: center;
}
</style>