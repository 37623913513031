import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import store from './store'
import VueYandexMetrika from 'vue-yandex-metrika'

import './assets/css/grid.css';
import './assets/css/utils.css';
import './assets/css/main.css';

Vue.use(VueYandexMetrika, {
    id: 91188622,
    env: 'production',
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
})

Vue.use(vueCustomElement)

App.store = store

Vue.customElement('fb-widget', App)