import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = 'https://feedback.verra.ru/api/';

Vue.use(Vuex)


export default new Vuex.Store({

    state: {
        docID: null,
        clientName: null,
        flagMessenger: null,
        urlYandex: null,
        urlGis: null,
        urlGoogle: null,
        uuid: null,

        step: 1,
        rating: 0,
        recommendation: [],
        comment_text: null,
        social: [],
    },

    mutations: {
        SET_STEP(state, data) {
            state.step = data
        },

        SET_OLD_RATING(state, data) {
            state.rating = data
        },

        SET_RECOMMENDATION(state, data) {
            state.recommendation = data
        },

        SET_COMMENT(state, data) {
            state.comment_text = data
        },

        SET_SOCIAL(state, data) {
            state.social = data
        },

        SET_DOCID(state, data) {
            state.docID = data
        },

        SET_CLIENT_NAME(state, data) {
            state.clientName = data
        },

        SET_FLAG_MESSENGER(state, data) {
            state.flagMessenger = data
        },

        SET_URL_YA(state, data) {
            state.urlYandex = data
        },

        SET_URL_GIS(state, data) {
            state.urlGis = data
        },
        SET_URL_GOOGLE(state, data) {
            state.urlGoogle = data
        },

        SET_UUID(state, data) {
            state.uuid = data
        },


    },

    actions: {
        async NPSInfo(store) {
            let param = {
                type: 'NPSInfo',
                docId: store.state.docID
            }

            param = Object.entries(param).map(([key, val]) => `${key}=${val}`).join('&');

            let {data} = await axios.post('', param);

            await store.commit('SET_CLIENT_NAME', data.clientName)
            await store.commit('SET_FLAG_MESSENGER', data.flagMessenger)
            await store.commit('SET_URL_YA', data.urlYandex)
            await store.commit('SET_URL_GIS', data.url2Gis)
            await store.commit('SET_URL_GOOGLE', data.urlGoogle)

            return data;
        },

        async clientFeedbackMessenger(store) {
            let param = {
                type: 'clientFeedbackMessenger',
                anketaID: store.state.uuid,
                messenger: store.state.social
            }
            param = Object.entries(param).map(([key, val]) => `${key}=${val}`).join('&');

            let {data} = await axios.post('', param);

            return data;

        },

        async clientFeedbackComment(store) {
            let param = {
                type: 'clientFeedbackComment',
                anketaID: store.state.uuid,
                flags: store.state.recommendation,
                comment: store.state.comment_text
            }
            param = Object.entries(param).map(([key, val]) => `${key}=${val}`).join('&');

            let {data} = await axios.post('', param);

            return data;
        },

        async clientFeedbackRate(store) {
            let param = {
                type: 'clientFeedbackRate',
                docId: store.state.docID,
                stars: store.state.rating
            }
            param = Object.entries(param).map(([key, val]) => `${key}=${val}`).join('&');

            let {data} = await axios.post('', param);

            await store.commit('SET_UUID', data)

            return data;
        }
    }

})