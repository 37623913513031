<template>
  <div class="component" v-if="$store.state.step == 4" id="final">

    <div class="final_title" v-if="$store.state.rating == 5">
      Спасибо, это лучшая благодарность для нашей команды!
    </div>
    <div class="final_text" v-if="$store.state.rating == 5">
      Нам будет приятно, если о вашем мнении узнают #Друзья_VERRA.
    </div>

    <div class="final_text_v2" v-show="$store.state.rating < 5">
      Спасибо,<br>что помогаете нам<br>стать лучше!
    </div>


    <div class="final_maps" v-if="$store.state.rating == 5">
      <div class="final_map" v-for="map in maps" :key="map.id">
        <a class="final_map__wrapper def-tr" :href="map.link" target="_blank">
          <div class="final_map_img">
            <img :src="map.img" alt="">
          </div>
          <div class="final_map_name">
            {{ map.name }}
          </div>
        </a>
      </div>
    </div>

    <div class="final_services_title">
      Интересные сервисы для вас
    </div>

    <div class="final_services">
      <div class="final_service" v-for="service in services" :key="service.id">
        <a class="final_service__wrapper def-tr" :href="service.link" target="_blank"
           @click="this.$metrika.reachGoal('links')">
          <div class="final_service_img">
            <img :src="service.img" alt="">
          </div>
          <div class="final_service_name" v-html="service.name">
          </div>
        </a>
      </div>
    </div>

    <div class="final_btn" v-if="$store.state.rating == 5">
      <div class="final_btn__wrapper">
        <button @click="nextStep">Пропустить</button>
      </div>
    </div>

  </div>
</template>

<script>

import ya from '../assets/img/ya.svg';
import gis from '../assets/img/gissvg.svg';
import google from '../assets/img/googlesvg.svg';
import travel from '../assets/img/travel.svg';
import music from '../assets/img/music.svg';
import garant from '../assets/img/garant.svg';

export default {

  name: 'step_4',

  data: () => {
    return {
      maps: [],
      services: [
        {
          id: 1,
          name: 'VERRA<br>TRAVEL',
          img: travel,
          link: 'https://www.travel.verra.ru/'
        },
        {
          id: 2,
          name: 'VERRA<br>MUSIC',
          img: music,
          link: 'https://t.me/verra_music'
        },
        {
          id: 3,
          name: 'Гарантия<br>VERRA',
          img: garant,
          link: 'https://verra.ru/service'
        }
      ]
    }

  },

  mounted() {
    this.is_loading = false

    this.maps = [
      {
        id: 1,
        name: 'Яндекс',
        img: ya,
        link: this.yandex_map
      },
      {
        id: 2,
        name: '2ГИС',
        img: gis,
        link: this.gis_map
      },
      {
        id: 3,
        name: 'Google Maps',
        img: google,
        link: this.google_map
      }
    ]
  },

  methods: {
    nextStep() {
      this.$metrika.reachGoal('final')

      history.pushState({}, 'Step 5', '/step_5')

      this.$store.commit('SET_STEP', 5)
    }
  },

  computed: {

    yandex_map: {
      get() {
        console.log(this.$store.state.urlYandex)
        return this.$store.state.urlYandex
      }
    },

    gis_map: {
      get() {
        return this.$store.state.urlGis
      }
    },
    google_map: {
      get() {
        console.log(this.$store.state.urlGoogle)
        return this.$store.state.urlGoogle
      }
    },
  }

}

</script>

<style scoped>
.final_title {
  font-size: 18px;
  color: #231F20;
  margin: 20px 0;
  padding: 0 30px;
  text-align: center;
}

.final_text {
  font-size: 18px;
  text-align: center;
  color: #231F20;
  margin: 20px 0;
  padding: 0 30px;
}

.final_maps {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
}


.final_map__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.final_map_name {
  margin-top: 10px;
  font-weight: 600;
  color: #231F20;
}

.final_services_title {
  font-size: 18px;
  color: #231F20;
  margin: 60px 0 20px 0;
}

.final_services {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.final_service {
  margin-right: 35px;
}

.final_services .final_service:last-child {
  margin-right: 0;
}

.final_service__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.final_service_name {
  font-weight: 600;
  color: #231F20;
  margin-top: 10px;
  text-align: center;
}

.final_map__wrapper,
.final_service__wrapper {
  cursor: pointer;
  text-decoration: unset;
}

.final_service__wrapper:hover {
  opacity: .35;
}

.final_btn {
  width: 100%;
  margin-top: 40px;
}

.final_btn__wrapper {
  padding: 0 40px;
}

.final_btn__wrapper button {
  width: 100%;
  height: 50px;
  border: 1px solid rgba(145, 154, 161, .5);
  border-radius: 10px;
  background: #fff;
  font-size: 18px;
  color: #919AA1;
}

.final_text_v2 {
  margin: 100px 0 110px;
  text-align: center;
  font-size: 18px;
  color: #231F20;
}

</style>