 <template>
  <div class="component" v-show="$store.state.step == 1" v-if="!is_loading" id="start">
    <div class="start__text">
      Благодарим за доверие!
    </div>

    <div class="fb_starts__text">
      Оцените уровень
      <br>
      нашего профессионализма
    </div>

    <div class="fb_starts">
      <div v-for="n in 5" :key="'star-' + n" class="fb_starts_item">
        <img :src="stars >= n ? star : star_empty" :data-rate="n" alt="star" @click="setRating" @mouseover="mouseoverRating" @mouseleave="mouseleaveRating">
      </div>
    </div>
    <transition name="fade">
      <div class="start_next" v-show="$store.state.rating > 0">
        <div class="start_next__wrapper">
          <button @click="nextStep">Отправить</button>
        </div>
      </div>
    </transition>


    <div class="fb_footer">
      <img :src="cars" alt="cars">
    </div>
  </div>
</template>

<script>

import star_empty from './../assets/img/star_empty.svg';
import star from './../assets/img/star.svg';
import cars from './../assets/img/cars.png';

export default {

  name: 'step_1',

  data: () => {

    return {
      star_empty,
      star,
      cars,
      stars: 0,
      is_loading: false,

    }

  },

  mounted() {
      history.pushState({docId: this.docID}, 'Step 1', '/step_1')
  },

  methods: {
    setRating(e) {
      this.stars = e.target.getAttribute('data-rate')
      this.rating = this.stars
    },

    mouseoverRating(e) {
      this.rating = this.stars
      this.stars = e.target.getAttribute('data-rate')
    },

    mouseleaveRating(e) {
      this.stars = this.rating
    },

    async nextStep(e) {
      await this.$store.dispatch('clientFeedbackRate')

      this.$metrika.reachGoal('rating')

      if (this.stars == 5) {
        if (this.$store.state.flagMessenger) {
          history.pushState({docId: this.docID}, 'Step 4', '/step_4')
          this.$store.commit('SET_STEP', 4)
        } else {
          history.pushState({docId: this.docID}, 'Step 3', '/step_3')
          this.$store.commit('SET_STEP', 3)
        }
      } else {
        history.pushState({docId: this.docID}, 'Step 2', '/step_2')
        this.$store.commit('SET_STEP', 2)
      }
    },

    async clientFeedbackRate() {

      if (this.rating == null || this.rating === '') return;

      try {

        await this.$store.dispatch('clientFeedbackRate')

      } catch (e) {

      }
    }

  },

  computed: {
    docID: {
      get() {
        return this.$store.state.docID
      },

      set(number) {
        this.$store.commit('SET_DOCID', number)
        return;
      },
    },
    rating: {
      get() {
        return this.$store.state.rating
      },

      set(number) {
        this.$store.commit('SET_OLD_RATING', number)
        return;
      },
    }
  }

}

</script>

<style scoped>
.start__text {
  font-size: 18px;
  color: #231F20;
  line-height: 1.2;
  text-align: center;
  margin: 25px 0 0;
}

.start_next {
  width: 100%;
  margin-top: 30px;
}

.fb_starts__text {
  margin-top: 100px;
  font-size: 18px;
  text-align: center;
  color: #231F20;
}

.start_next__wrapper {
  padding: 0 40px;
}

.start_next__wrapper > button {
  width: 100%;
  background: #4A6586;
  height: 50px;
  border: unset;
  border-radius: 10px;
  font-size: 22px;
  color: #FFFFFF;
}

.fb_footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 90px;
}

.fb_footer img {
  max-width: 300px;
}
</style>